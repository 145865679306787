var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('LoadingPage'):_c('div',{},[_c('div',[_c('NbPageTitle',{attrs:{"title":_vm.$t('importResultPage.title'),"subTitle":_vm.$t('userProfilePage.firstText')}}),_c('section',{staticClass:"ml-3"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-50"},[_c('NbStatus',{attrs:{"type":"","text":`${_vm.$t('importResultPage.totalProduct')}: 
                    ${_vm.total_invalid + _vm.total_valid || 0}`}}),_c('NbStatus',{attrs:{"type":"success","classes":"body-3","text":`${_vm.$t('importResultPage.totalValid')}: 
                    ${_vm.total_valid || 0}`}}),_c('NbStatus',{attrs:{"type":"danger","text":`${_vm.$t('importResultPage.totalRejected')}: 
                    ${_vm.total_invalid || 0}`}})],1),(!_vm.total_valid)?_c('div',[_c('Alert',{staticClass:"mb-5",attrs:{"type":"danger","width":"276","height":"122","size":"lg","verticalAling":"center","icon":"far fa-times-circle mr-3","message":_vm.$t('importResultPage.noValidProducts'),"link":{
                src: '/settings/boxes-products',
                text: `${_vm.$t('importResultPage.gotoProducts')}>`,
                newLine: true,
              }}})],1):_vm._e()])]),(_vm.total_valid)?_c('div',{staticClass:"mb-5"},[_c('NbButton',{staticClass:"button-light mr-1",attrs:{"variant":"secondary","data-toggle":"modal","data-target":"#modalCancelImport"}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('NbButton',{on:{"~click":function($event){return _vm.validImport()}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1):_vm._e()])],1),_c('section',{staticClass:"ml-3"},[_c('div',{staticClass:"heading-4"},[_vm._v(" "+_vm._s(_vm.$t("importResultPage.importLog"))+" ")]),_c('NbTablev2',{attrs:{"tableOf":"productsImport","allFields":_vm.allFields,"fields":_vm.fields,"startPerPage":999,"hiddeTableOptions":true},on:{"tableLoaded":function($event){return _vm.tableLoaded($event)},"reloadFields":function($event){_vm.fields = $event}},scopedSlots:_vm._u([{key:"cell(valid)",fn:function(data){return [_c('NbBadge',{staticClass:"fit-content",attrs:{"type":data.item.valid ? 'success' : 'danger',"text":data.item.valid
              ? _vm.$t('importResultPage.success')
              : _vm.$t('importResultPage.error')}})]}}]),model:{value:(_vm.log),callback:function ($$v) {_vm.log=$$v},expression:"log"}})],1),_c('NbModal',{attrs:{"id":"modalCancelImport","modalConfig":"modal-dialog-centered","width":"500px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"heading-4"},[_vm._v(" "+_vm._s(_vm.$t("importResultPage.cancelImport"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("importResultPage.confirmCancel"))+" ")]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('NbButton',{attrs:{"variant":"secondary","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('NbButton',{attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.cancelImport()}}},[_vm._v(" "+_vm._s(_vm.$t("importResultPage.cancelImport"))+" ")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }