<template>
  <LoadingPage v-if="loaded" />
  <div v-else style>
    <div>
      <NbPageTitle
        :title="$t('importResultPage.title')"
        :subTitle="$t('userProfilePage.firstText')"
      />
      <section class="ml-3">
        <div class="mb-3">
          <div class="d-flex">
            <div class="w-50">
              <NbStatus
                type=""
                :text="`${$t('importResultPage.totalProduct')}: 
                      ${total_invalid + total_valid || 0}`"
              />
              <NbStatus
                type="success"
                classes="body-3"
                :text="`${$t('importResultPage.totalValid')}: 
                      ${total_valid || 0}`"
              />
              <NbStatus
                type="danger"
                :text="`${$t('importResultPage.totalRejected')}: 
                      ${total_invalid || 0}`"
              />
            </div>
            <div v-if="!total_valid">
              <Alert
                class="mb-5"
                type="danger"
                width="276"
                height="122"
                size="lg"
                verticalAling="center"
                icon="far fa-times-circle mr-3"
                :message="$t('importResultPage.noValidProducts')"
                :link="{
                  src: '/settings/boxes-products',
                  text: `${$t('importResultPage.gotoProducts')}>`,
                  newLine: true,
                }"
              />
            </div>
          </div>
        </div>

        <div v-if="total_valid" class="mb-5">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            data-target="#modalCancelImport"
            class="button-light mr-1"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click.once="validImport()">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </section>
    </div>
    <section class="ml-3">
      <div class="heading-4">
        {{ $t("importResultPage.importLog") }}
      </div>

      <NbTablev2
        tableOf="productsImport"
        :allFields="allFields"
        :fields="fields"
        :startPerPage="999"
        :hiddeTableOptions="true"
        @tableLoaded="tableLoaded($event)"
        @reloadFields="fields = $event"
        v-model="log"
      >
        <template #cell(valid)="data">
          <NbBadge
            class="fit-content"
            :type="data.item.valid ? 'success' : 'danger'"
            :text="
              data.item.valid
                ? $t('importResultPage.success')
                : $t('importResultPage.error')
            "
          />
        </template>
      </NbTablev2>
    </section>
    <!-- modals -->
    <NbModal
      id="modalCancelImport"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("importResultPage.cancelImport") }}
        </div>
      </template>
      <template v-slot:body>
        {{ $t("importResultPage.confirmCancel") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton data-dismiss="modal" @click="cancelImport()">
            {{ $t("importResultPage.cancelImport") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbStatus from "@/components/alerts/NbStatus.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbModal from "@/components/modal/NbModal.vue";
import Alert from "@/components/alerts/Alert.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

import ProductService from "../../services/ProductService";

const productService = new ProductService();

export default {
  name: "LogImport",
  components: {
    LoadingPage,
    NbPageTitle,
    NbButton,
    NbStatus,
    NbTablev2,
    NbBadge,
    NbModal,
    Alert,
  },
  data: () => {
    return {
      loaded: false,
      button_disable: false,
      log: [],
      valid: [],
      fields: [],
    };
  },
  beforeMount() {
    this.log = this.$router.history.current.params.data;
    this.valid = this.log.filter((obj) => obj.valid);
  },
  methods: {
    validImport() {
      this.button_disable = true;
      this.$helpers.toast("Importing products", "warning");

      productService.validImport(this.valid).then((response) => {
        this.$helpers.toast(response.data.messages[0], "success");
        setInterval(this.$router.push("/settings/boxes-products", 2000));
      });
    },
    cancelImport() {
      this.$router.push("/settings/boxes-products");
    },
  },
  computed: {
    total_valid: function () {
      return this.log.filter((obj) => obj.valid).length;
    },
    total_invalid: function () {
      return this.log.filter((obj) => !obj.valid).length;
    },
    allFields() {
      return [
        { key: "sku", label: this.$t("sku") },
        { key: "name", label: this.$t("name") },
        { key: "valid", label: this.$t("importResultPage.status") },
        { key: "log", label: this.$t("importResultPage.log") },
      ];
    },
  },
};
</script>
